import React from "react"
import { graphql } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { 
  ProgramSection, 
  ProgramOverview } from '../styledComponents/program'
import {
    Section,
} from "../styledComponents/section"
import HeroBanner from "../components/HeroBanner"
import { HeroDescription, HeroText, HeroHeader } from "../styledComponents/heroBanner"  
import ConsultationForm from "../components/consultationForm"

const Program = ({ data }) => {
  return (
    <>
    <Layout hasHero>
      <SEO title={data.wpPage.title} />

      <HeroBanner image={data.wpPage.heroSection.kaarmibeingHeroBackground.localFile.publicURL} backgroundPosition="-223px">
          <HeroText>
            <HeroHeader>
                {data.wpPage.heroSection.kaarmibeingHeroHeading}
            </HeroHeader>
            <HeroDescription>{data.wpPage.heroSection.kaarmibeingHeroLedeText}</HeroDescription>
            <AnchorLink to="/personal-consultation#overview" stripHash title="Find out how the KaarmiBeing team can help with specific personal challenges">Find out more</AnchorLink>
          </HeroText>
      </HeroBanner>

      <ProgramSection id="overview">
        <ProgramOverview>
          <h2>Personal Consultation</h2>
          <p>If you have a specific personal challenge then please get in touch to book a personal consultation.</p>
          <h3>The personal consultations are specifically focused on:</h3>
          <ul>
            <li>Teaching you specific KaarmiBeing techniques which will fit your personal requirements</li>
            <li>Expanding on the material taught in the KaarmiBeing programs and applying it for personal improvements in your life</li>
            <li>Teaching you how to find practical solutions and move forward in specific situations</li>
            <li>Identifying the specific personality type of a person in your life and how to communicate with them for an optimum relationship</li>
            <li>Health and wellness tips</li>
            <li>Personal consultation on the KaarmiBeing breathing and meditation techniques</li>
            <li>Overcoming the obstacles in your mind that are blocking you from achieving your goals</li>
            <li>Anything in your life that you wish to learn to apply the KaarmiBeing philosophy to</li>
          </ul>
          <p>These sessions are one hour and take place online. Please fill in the form below and include some information about what you would like to focus on in your session. We will reply to your email within 48 hours to book a session with one of the KaarmiBeing team.</p>
        </ProgramOverview>
      </ProgramSection>     

      <Section>
      <ConsultationForm />
      </Section>
    </Layout>
    </>
  )
}

export default Program

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    wpPage(slug: { eq: "personal-consultation" }) {
      title
      content
      heroSection {
        kaarmibeingHeroBackground {
            uri
            localFile {
              publicURL
            }
        }
        kaarmibeingHeroHeading
        kaarmibeingHeroLedeText
        kaarmibeingHeroPrimaryCta {
          title
          url
        }
      }
      OurProgram {
        programData {
          programContent
          programHeader
          programLede
          svg {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`
